import React, {ReactElement} from "react";
import FrameButton from "../components/buttons/FrameButton";
import {AiFillRocket} from "react-icons/ai";
import {FiArrowLeft} from "react-icons/fi";
import {GiPresent} from "react-icons/gi";
import {GoGraph} from "react-icons/go";
import FrameOneSelect from "../components/inputs/FrameOneSelect";

function ComponentDemoPage(): ReactElement {

	return (
		<div className="demo-page">
			<h1>
				Component Demo Page
			</h1>

			<div className="demo-page_spaced-components">

				<div>
					<h1>Heading 1 - Hero</h1>
					<h2>Heading 2 - Title</h2>
					<h3>Heading 3 - Header</h3>
					<h4>Heading 4 - Subheader</h4>
					<h5>Heading 5 - Section Header</h5>
					<p>P1 Body - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut in purus lectus. Vivamus
						tempus blandit ante nec efficitur. Proin bibendum diam odio, porttitor iaculis odio congue
						eget.</p>
					<p className="p2-sub-body">P2 Sub Body - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
						in purus lectus. Vivamus tempus blandit ante nec efficitur. Proin bibendum diam odio, porttitor
						iaculis odio congue eget.</p>
					<p className="p3-caption">P3 Caption - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
						in purus lectus. Vivamus tempus blandit ante nec efficitur. Proin bibendum diam odio, porttitor
						iaculis odio congue eget.</p>
				</div>

				<div className="demo-page_spaced-components_row">
					<FrameButton
						<React.AnchorHTMLAttributes<HTMLAnchorElement>>
						color="purple"
						size="large"
						href="https://www.frameonesoftware.com"
						forwardProps={{
							target: "_blank",
						}}
					>
						Large CTA Button
					</FrameButton>

					<FrameButton
						color="purple-outline"
						size="large"
					>
						Large CTA Button Outline
					</FrameButton>

					<FrameButton
						color="gray"
						size="large"
					>
						Large CTA Button Gray
					</FrameButton>
				</div>

				<div className="demo-page_spaced-components_row">
					<FrameButton
						color="purple"
						size="normal"
					>
						Primary Button
					</FrameButton>

					<FrameButton
						color="purple-outline"
						size="normal"
					>
						Primary Button Outline
					</FrameButton>

					<FrameButton
						color="gray"
						size="normal"
					>
						Primary Button Gray
					</FrameButton>
				</div>

				<div className="demo-page_spaced-components_row">
					<FrameButton
						color="purple"
						size="narrow"
					>
						Secondary Button
					</FrameButton>

					<FrameButton
						color="purple-outline"
						size="narrow"
					>
						Secondary Button Outline
					</FrameButton>

					<FrameButton
						color="gray"
						size="narrow"
					>
						Secondary Button Gray
					</FrameButton>
				</div>

				<div className="demo-page_spaced-components_row">
					<FrameButton
						color="purple"
						size="icon"
						leftIcon={AiFillRocket}
					/>

					<FrameButton
						color="purple-outline"
						size="icon"
						leftIcon={GoGraph}
					/>

					<FrameButton
						color="gray"
						size="icon"
						leftIcon={FiArrowLeft}
					/>

					<FrameButton
						color="white-drop-shadow"
						size="icon"
						leftIcon={GiPresent}
					/>
				</div>

				<div className="demo-page_spaced-components_row">
					<div>
						<label>Standard Input</label>
						<input
							placeholder="Placeholder text..."
						/>
					</div>

					<div>
						<label>Disabled Input</label>
						<input
							placeholder="Placeholder text..."
							disabled={true}
						/>
					</div>
				</div>

				<div className="demo-page_spaced-components_row">
					<div>
						<label>Standard Dropdown</label>
						<FrameOneSelect >
							<option value={1}>This is an option</option>
							<option value={2}>This is another option</option>
						</FrameOneSelect>
					</div>

					<div>
						<label>Disabled Dropdown</label>
						<FrameOneSelect
							// placeholder="Placeholder text..."
							disabled={true}
						>
							<option value={1}>This is an option</option>
							<option value={2}>This is another option</option>
						</FrameOneSelect>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ComponentDemoPage;
