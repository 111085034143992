"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletTypeToJSON = exports.WalletTypeFromJSONTyped = exports.WalletTypeFromJSON = exports.WalletType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var WalletType;
(function (WalletType) {
    WalletType["WALLETCONNECT"] = "WALLET_CONNECT";
    WalletType["MAGIC"] = "MAGIC";
    WalletType["SIGNEDURL"] = "SIGNED_URL";
    WalletType["SOLANA"] = "SOLANA";
    WalletType["PRIVY"] = "PRIVY";
    WalletType["THIRDWEB"] = "THIRDWEB";
})(WalletType = exports.WalletType || (exports.WalletType = {}));
function WalletTypeFromJSON(json) {
    return WalletTypeFromJSONTyped(json, false);
}
exports.WalletTypeFromJSON = WalletTypeFromJSON;
function WalletTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.WalletTypeFromJSONTyped = WalletTypeFromJSONTyped;
function WalletTypeToJSON(value) {
    return value;
}
exports.WalletTypeToJSON = WalletTypeToJSON;
